import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import {
  configure,
  infiniteHits,
  panel,
  refinementList,
  rangeSlider,
  searchBox,
} from 'instantsearch.js/es/widgets';

const searchClient = algoliasearch(
  'CS1CNDC94L',
  'a0f81fcb9b1c82dba6be40f3dd6678f8'
);

const search = instantsearch({
  indexName: 'prod_ZseniLeszekVideoList_v7',
  searchClient,
  insights: true,
});

search.addWidgets([
  searchBox({
    container: '#searchbox',
	placeholder: 'Mit keresel?',
	autofocus: true,
	searchAsYouType: true,
	showReset: true,
	showSubmit: true,
	showLoadingIndicator: true,
  }),
  infiniteHits({
    container: '#hits',
    templates: {
      item: (hit, { html, components }) => {
		  const urlLink = "https://www.zsenileszek.hu/" + components.Snippet({ hit, attribute: 'titled-id-hun' }).props.parts[0].map(part => part ? part.value : '').join('');
		  const urlThumbnail = components.Snippet({ hit, attribute: 'youtube-link-hun-thumbnail' }).props.parts[0].map(part => part ? part.value : '').join('');
		  const headerTooltip = components.Snippet({ hit, attribute: 'title-hun' }).props.parts[0].map(part => part ? part.value : '').join('');
		  return html`
        <article class="hit-card InterfaceDemoHit">
		  <a href="${urlLink}">
			  <div class="hit-card-thumbnail">
				<img src="${urlThumbnail}"></img>
			  </div>
			  <span class="hit-card-length badge badge-primary badge-pill">${components.Highlight({ hit, attribute: 'video-length-hun' })}</span>
			  <div class="hit-card-header">
					<h3 title="${headerTooltip}">${components.Highlight({ hit, attribute: 'title-hun' })}</h3>
			  </div>
			  <p class="hit-card-text">${components.Highlight({ hit, attribute: 'short-description-hun' })}</p>
			  <span class="hit-card-grade badge badge-primary badge-pill">${components.Highlight({ hit, attribute: 'grade-text-hun' })}</span>
		  </a>
        </article>
      `},
	  empty: () => "Nincs találat",
	  showPreviousText: () => "Előző találatok",
	  showMoreText: () => "További találatok",
    },
  }),
  configure({
    hitsPerPage: 12,
  }),
  panel({
    templates: { header: (h, {html}) => { return html`Fejezet` } },
  })(refinementList)({
    container: '#topic-list',
    attribute: 'topic-hun',
  }),
  
  panel({
    templates: { header: (h, {html}) => { return html`Osztály` } },
  })(rangeSlider)({
    container: '#grade-list',
    attribute: 'grade-hun',
	min: 1,
	max: 12,
	precision: 1,
	step: 1,
	pips: false
  }),
]);

search.start();